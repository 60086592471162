@import "theme-default";
@import "theme-dark";
@import "typography";
@import "sizes";

:root {
  font-size: 16px;
  --vh100-offset: 0px;
  --font-family: var(--font-open-sans), sans-serif;
}

.dark-theme {
  @include theme-dark;
}

html {
  touch-action: manipulation;
  font-feature-settings:
    "rlig" 1,
    "calt" 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}

body {
  --color: var(--text-color);

  background: var(--white);
  color: var(--color);
  font-family: var(--font-family);

  a {
    color: var(--text-color);
    text-decoration: none;

    &:hover {
      //color: var(--accent-dark);
    }
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.SmartCaptcha-Shield {
  z-index: 99999;
}

.SmartCaptcha-Overlay {
  z-index: 999999999 !important;
}

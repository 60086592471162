:root {
  --background: #fff; //#f1f0f5; // light-gray (bg)
  --accent: #389d9f; // accent
  --accent-dark: #389d9f; // accent-dark

  --shoes-theme-text-color: #63b7ad;
  --shoes-theme-accent: #389d9f;
  --shoes-theme-accent-background: #c1dddd;

  //--shoes-theme-text-color: #f17288;
  //--shoes-theme-accent: #ff3a5c;
  //--shoes-theme-accent-background: #fad2d9;

  --stroke-color: #e1e1e1; // light-gray (strokes)
  --input-border-color: #b0b0b0;
  --medium-gray: #8d8d9a; // medium-gray
  --dark-gray: #3e3e3e; // dark-gray
  --dark-dark-gray: #1d1d1d; // dark-dark-gray
  --white: #ffffff; // white
  --black: #000000; // black
  --unicorn-pink: #eb69c1; // unicorn-pink

  --poizon-gradient: linear-gradient(38deg, #54b5b7 0%, #7acacc 100%);
  --point-gradient: linear-gradient(85deg, #3d67ff 3.74%, #c13dff 131.73%);

  --text-color: #000000;
  --text-hint-color: #5f6165; // dark/telegram/hint_color

  --header-bg: black;
  --header-color: white;
  --header-border-width: 0;
  --header-border: transparent;

  --ui-main-light-gray: #f6f6f6;
  --ui-main-white: #ffffff;
  --ui-main-black: #000000;
  --ui-gray-gray-20: #eaeaea;
  --ui-gray-gray-30: #c1c1c1;
  --ui-gray-gray-50: #848484;
  --ui-main-link-color: #3e98ec;
  --ui-main-black-lite: #1b1b1b;
  --ui-system-bad: #ff7373;
  --ui-main-accent-pink: #f447a0;
  --ui-system-good: #a8e17b;

  --red: #fc5230;
  --primary: #389d9f;

  --font: "SF Pro Display", -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
}

@import "./media";

:root {
  --container-width: 1300px;
  --container-max-width: 1400px;
  --container-horizontal-padding: 20px;
  --container-horizontal-padding-negative: -20px;

  @include md {
    --container-horizontal-padding: 20px;
    --container-horizontal-padding-negative: -20px;
  }
  @include sm {
    --container-horizontal-padding: 15px;
    --container-horizontal-padding-negative: -15px;
  }
}
